import {
  Button,
  ColorModeProvider,
  Column,
  Columns,
  Input,
  Section,
  SendIcon,
  Stack,
} from "@dangerfarms/ui-system";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { PrismicText } from "@dangerfarms/prismic";
import { trackEvent } from "@dangerfarms/analytics";
import css from "@styled-system/css";
import styled from "styled-components";

const BACKGROUND_DISPLAY_BREAKPOINT = 600;

const NewsletterFormSection = styled(Section)`
  min-height: 0;
  box-shadow: inset 0 3px 9px 0 rgba(0, 0, 0, 0.25);

  @media screen and (min-width: ${BACKGROUND_DISPLAY_BREAKPOINT}px) {
    background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
    background-position-x: calc(
      100% + (${({ theme }) => theme.contentWidth[0]} - 100vw) / 1.7
    );
    background-repeat: no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    background-position-x: calc(
      100% + (${({ theme }) => theme.contentWidth[0]} - 100vw) / 1.5
    );
    background-size: contain;
  }

  @media screen and (min-width: ${({ theme }) => theme.contentWidth[0]}) {
    background-position-x: 100%;
  }

  ${css({
    paddingTop: 5,
    paddingBottom: [5, 6],
  })};
`;

const NewsletterSignUpText = styled(PrismicText)`
  ${css({
    maxWidth: ["50%", "650px"],
  })};

  @media screen and (max-width: ${BACKGROUND_DISPLAY_BREAKPOINT - 1}px) {
    max-width: 100%;
  }
`;

const EmailField = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmailInput = styled(Input)`
  width: 100%;
  height: 70px;
  border: none;
`;

const SendButton = styled(Button)`
  width: 70px;
  height: 70px;
  padding: 0;
`;

const newsletterFormComponentQuery = graphql`
  query newsletterFormComponent {
    prismic {
      allFooter_and_contacts {
        edges {
          node {
            newsletter_form_heading
            newsletter_form_text
            newsletter_form_background
          }
        }
      }
    }
    site {
      siteMetadata {
        formspreeFormId
      }
    }
  }
`;

const NewsletterForm = () => {
  return (
    <StaticQuery
      query={`${newsletterFormComponentQuery}`}
      render={data => {
        const { formspreeFormId } = data.site.siteMetadata;

        const {
          newsletter_form_heading,
          newsletter_form_text,
          newsletter_form_background,
        } = data.prismic.allFooter_and_contacts.edges[0].node;

        return (
          <ColorModeProvider mode="accent">
            <NewsletterFormSection
              backgroundImageUrl={newsletter_form_background.url}
              bg="bg"
            >
              <Stack>
                <PrismicText
                  richText={newsletter_form_heading}
                  variant="heading"
                />

                <Columns>
                  <Column
                    boxHeight="70px"
                    flex={35}
                    marginBottom={[3, 0]}
                    paddingY="5px"
                  >
                    <NewsletterSignUpText
                      lineHeight="30px"
                      richText={newsletter_form_text}
                      weight="news"
                    />
                  </Column>
                  <Column boxHeight="70px" flex={22}>
                    <form
                      action={`https://formspree.io/${formspreeFormId}`}
                      method="POST"
                      onSubmit={() => trackEvent("Newsletter Form", "Submit")}
                    >
                      <EmailField>
                        <EmailInput
                          aria-label="Email address"
                          autoCapitalize="false"
                          autoCorrect="false"
                          id="newsletter-sign-up-email"
                          name="_newsletter_signup_email"
                          placeholder="Email address"
                          required
                          type="email"
                        />

                        <SendButton type="submit">
                          <SendIcon size={70} />
                        </SendButton>
                      </EmailField>
                    </form>
                  </Column>
                </Columns>
              </Stack>
            </NewsletterFormSection>
          </ColorModeProvider>
        );
      }}
    />
  );
};

export default NewsletterForm;
