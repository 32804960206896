import { Box, ContentBlock, Stack, TextContent } from "@dangerfarms/ui-system";
import React from "react";
import { PrismicText, TableOfContents } from "@dangerfarms/prismic";
import Page from "./Page";

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const PolicyPage = ({ pageTitle, prismic }) => {
  return (
    <Page description={prismic.description} title={pageTitle}>
      <Box as="article" paddingX="gutter" paddingY={8}>
        <ContentBlock>
          <Stack space={2}>
            <Stack space={0.5}>
              <PrismicText richText={prismic.policy_heading} />
              <PrismicText richText={prismic.policy_date} variant="lede" />
            </Stack>
            <Stack space={3}>
              <TextContent>
                <PrismicText richText={prismic.policy_intro} />
              </TextContent>
              <TableOfContents
                ariaLabel="Table of contents"
                of={prismic.policy_content}
              />
              <TextContent>
                <PrismicText richText={prismic.policy_content} withAnchors />
              </TextContent>
            </Stack>
          </Stack>
        </ContentBlock>
      </Box>
    </Page>
  );
};
/* eslint-enable */

export default PolicyPage;
