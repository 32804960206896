import React from "react";
import { graphql, StaticQuery } from "gatsby";
import StructuredData from "./StructuredData";

const organizationDataComponentQuery = graphql`
  query organizationDataComponent {
    site {
      siteMetadata {
        address {
          city
          countryCode
          line1
          line2
          locality
          postcode
        }
        baseUrl
        description
        email
        foundingDate
        geolocation {
          latitude
          longitude
        }
        logo
        title
        socialMediaLinks {
          active
          url
        }
      }
    }
  }
`;

const OrganizationStructuredData = () => {
  return (
    <StaticQuery
      query={`${organizationDataComponentQuery}`}
      render={data => {
        const {
          address,
          baseUrl,
          description,
          email,
          foundingDate,
          geolocation,
          logo,
          socialMediaLinks,
          title,
        } = data.site.siteMetadata;

        const organizationData = {
          "@context": "http://schema.org",
          "@type": "Organization",
          address: {
            "@type": "PostalAddress",
            addressCountry: address.countryCode,
            addressLocality: address.locality,
            addressRegion: address.city,
            postalCode: address.postcode,
            streetAddress: `${address.line1}, ${address.line2}`,
          },
          description,
          email,
          image: logo,
          foundingDate,
          foundingLocation: {
            "@type": "Place",
            address: {
              "@type": "PostalAddress",
              addressCountry: address.addressCountry,
              addressRegion: address.addressRegion,
            },
          },
          location: {
            "@type": "Place",
            address,
            geo: {
              "@type": "GeoCoordinates",
              latitude: geolocation.latitude,
              longitude: geolocation.longitude,
            },
          },
          logo,
          name: title,
          sameAs: socialMediaLinks
            .filter(link => link.active)
            .map(link => link.url),
          url: baseUrl,
        };

        return <StructuredData data={organizationData} />;
      }}
    />
  );
};

export default OrganizationStructuredData;
