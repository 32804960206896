import { Accordion } from "@dangerfarms/ui-system";
import { LogoGrid } from "@dangerfarms/website-core";
import React from "react";

const renderContent = fields =>
  fields[0] && fields[0].image ? <LogoGrid logos={fields} /> : null;

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const ServicesAccordion = ({ data }) => (
  <Accordion
    items={data.map(item => ({
      title: item.primary.title[0].text,
      lede: item.primary.content[0].text,
      renderContent: () => renderContent(item.fields),
    }))}
  />
);
/* eslint-enable */

export default ServicesAccordion;
