import { Text, Stack, Box } from "@dangerfarms/ui-system";
import React from "react";
import PropTypes from "prop-types";

const Testimonial = ({ quote, sourceName, sourceJobTitle }) => {
  return (
    <Box
      as="blockquote"
      borderLeft="1px solid"
      borderLeftColor="accent"
      pl={[2, 4]}
    >
      <Stack space={1}>
        <Text fontStyle="italic" variant="lede">
          {quote}
        </Text>
        <Box>
          {sourceName && (
            <Text color="accent" fontStyle="italic" weight="bold">
              {sourceName}
            </Text>
          )}
          {sourceJobTitle && (
            <Text color="accent" fontStyle="italic" weight="bold">
              {sourceJobTitle}
            </Text>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

Testimonial.propTypes = {
  quote: PropTypes.string.isRequired,
  sourceJobTitle: PropTypes.string.isRequired,
  sourceName: PropTypes.string.isRequired,
};

export default Testimonial;
