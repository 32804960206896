import React from "react";
import {
  Box,
  ColorModeProvider,
  CloseIcon,
  Logo,
  HamburgerIcon,
  PlainButton,
  Stack,
  Text,
} from "@dangerfarms/ui-system";
import {
  getHashFragment,
  isHash,
  linkResolver,
  normalizePrismicLink,
} from "@dangerfarms/prismic";
import { trackEvent } from "@dangerfarms/analytics";
import styled from "styled-components";
import { Link } from "gatsby";
import css from "@styled-system/css";
import ReachDialog from "@reach/dialog";
import SocialMediaLinks from "@dangerfarms/website-core/SocialMediaLinks";

const HamburgerButton = styled(PlainButton)`
  align-items: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 50%;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 29px;
  height: 55px;
  width: 55px;

  ${css({
    display: ["flex", "none"],
    right: "gutter",
  })}
`;

const CloseButton = styled(PlainButton)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 29px;
  height: 55px;
  width: 55px;

  ${css({
    right: "gutter",
  })}
`;

const InlineLink = styled(Link)`
  display: inline-block;
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const Dialog = styled(ReachDialog)`
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  ${css({
    backgroundColor: "bg",
    paddingX: "gutter",
  })}
`;

const NavLink = styled(Link)`
  text-decoration: none;
`;

const FragmentNavLink = styled(NavLink).attrs({ as: "a" })`
  cursor: pointer;
`;

const NavText = styled(Text).attrs({ as: "span" })`
  font-size: 1.6rem;
  text-align: center;

  ${css({
    fontWeight: "bold",
  })}
`;

const MiniNavText = styled(Text).attrs({ as: "span" })`
  text-align: center;

  ${css({
    color: "fg",
    fontWeight: "bold",
    fontSize: 2,
  })}
`;

const MobileList = styled(Stack).attrs({ align: "center", as: "ul" })`
  list-style: none;
`;

// Prop types always passed from <Nav />, you shouldn't be rendering this
// component directly.
//
// TODO: maybe some DRY opportunities with logo and nav link branching
//       (eg. if (isHash) stuff repeated in DesktopNav)
//
/* eslint-disable react/prop-types */
const MobileNav = ({ navLinks, scrollToHandlers }) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <HamburgerButton
        aria-labelledby="hamburger-button"
        onClick={() => setOpen(true)}
      >
        <HamburgerIcon alt="Menu" id="hamburger-button" />
      </HamburgerButton>
      <ColorModeProvider mode="accent">
        <Dialog aria-label="Nav menu" isOpen={isOpen}>
          <Stack flex={1} space={2}>
            <Box paddingTop={3}>
              <CloseButton
                aria-labelledby="hamburger-close-button"
                onClick={() => setOpen(false)}
              >
                <CloseIcon alt="Close" id="hamburger-close-button" size={20} />
              </CloseButton>

              <InlineLink aria-labelledby="df-mobile-nav-logo-title" to="/">
                <Logo alt="Danger Farms" id="df-mobile-nav-logo-title" />
              </InlineLink>
            </Box>
            <Nav>
              <Stack align="center" flex={1} paddingBottom={4} space={1.3}>
                <MobileList space={0.33}>
                  {navLinks.map(rawLink => {
                    if (
                      rawLink.link._linkType !== "Link.document" &&
                      rawLink.link._linkType !== "Link.web"
                    ) {
                      throw new Error(
                        `Unsupported link type ${rawLink.link._linkType} encountered when rendering header nav.`,
                      );
                    }

                    const link = normalizePrismicLink(rawLink.link);
                    const isInternal = link.link_type === "Document";

                    if (isInternal) {
                      const to = linkResolver(link);
                      return (
                        <li key={rawLink.label}>
                          <NavLink to={to}>
                            <NavText>{rawLink.label}</NavText>
                          </NavLink>
                        </li>
                      );
                    } else if (isHash(link)) {
                      const hashFragment = getHashFragment(link);
                      const scrollToHandler =
                        hashFragment === "#contact"
                          ? () => {
                              trackEvent("Contact Form", "View");
                              scrollToHandlers[hashFragment.slice(1)]();
                            }
                          : scrollToHandlers[hashFragment.slice(1)];
                      return (
                        <li key={rawLink.label}>
                          <FragmentNavLink
                            href={hashFragment}
                            onClick={event => {
                              event.preventDefault();
                              setOpen(false);
                              if (scrollToHandler) {
                                scrollToHandler();
                              }
                            }}
                          >
                            <NavText>{rawLink.label}</NavText>
                          </FragmentNavLink>
                        </li>
                      );
                    } else {
                      return (
                        <li key={rawLink.label}>
                          <NavLink as="a" href={link.url}>
                            <NavText>{rawLink.label}</NavText>
                          </NavLink>
                        </li>
                      );
                    }
                  })}
                </MobileList>

                <Box flex={1} />

                <Stack align="center" space={0.5}>
                  <NavLink to="/privacy">
                    <MiniNavText>Privacy</MiniNavText>
                  </NavLink>

                  <NavLink to="/cookies">
                    <MiniNavText>Cookies</MiniNavText>
                  </NavLink>
                </Stack>

                <SocialMediaLinks />
              </Stack>
            </Nav>
          </Stack>
        </Dialog>
      </ColorModeProvider>
    </React.Fragment>
  );
};
/* eslint-enable */

export default MobileNav;
