import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import StructuredData from "./StructuredData";

const webPageStructuredDataComponentQuery = graphql`
  query webPageStructuredDataComponent {
    site {
      siteMetadata {
        keywords
      }
    }
  }
`;

const WebPageStructuredData = ({ title, description, type }) => {
  return (
    <StaticQuery
      query={`${webPageStructuredDataComponentQuery}`}
      render={data => {
        const { keywords } = data.site.siteMetadata;
        const structuredData = {
          "@context": "http://schema.org",
          "@type": type,
          name: title,
          author: {
            "@type": "Organization",
            name: "Danger Farms",
          },
          keywords,
        };

        if (description) {
          structuredData.description = description;
        }

        return <StructuredData data={structuredData} />;
      }}
    />
  );
};

WebPageStructuredData.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
};

WebPageStructuredData.defaultProps = {
  description: null,
  type: "WebPage",
};

export default WebPageStructuredData;
