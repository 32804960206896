import {
  Box,
  CloseIcon,
  ColorModeProvider,
  ContentBlock,
} from "@dangerfarms/ui-system";
import { PrismicText } from "@dangerfarms/prismic";
import css from "@styled-system/css";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const GDPRNoticeContainer = styled.div`
  border-top: solid 3px;
  bottom: 0;
  position: fixed;
  width: 100%;

  ${css({
    borderColor: "accentBright.0",
    boxShadow: "mega",
    backgroundColor: "bg",
    paddingX: "gutter",
    zIndex: "popup",
  })}
`;

const IconButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  margin-right: -1rem;
  padding: calc(1rem + 2px);
`;

const GDPRNotice = ({ onClose }) => {
  return (
    <StaticQuery
      query={graphql`
        query gdprNoticeComponent {
          prismic {
            allGdpr_notices {
              edges {
                node {
                  gdpr_notice
                }
              }
            }
          }
        }
      `}
      render={data => {
        // Required check for no data being returned
        const prismicGDPRNotice = data.prismic.allGdpr_notices.edges
          .slice(0, 1)
          .pop();
        if (!prismicGDPRNotice) {
          return null;
        }
        const prismic = prismicGDPRNotice.node;

        return (
          <ColorModeProvider mode="dark">
            <GDPRNoticeContainer>
              <ContentBlock>
                <Box boxDisplay="flex" flexDirection="row">
                  <Box paddingY={2} width="90%">
                    <PrismicText
                      maxWidth="35em"
                      richText={prismic.gdpr_notice}
                      space={0.5}
                      variant="smallprint"
                    />
                  </Box>
                  <Box flex={1} />
                  <Box marginLeft={2}>
                    <IconButton aria-labelledby="gdpr-close" onClick={onClose}>
                      <CloseIcon alt="Close" id="gdpr-close" size={14} />
                    </IconButton>
                  </Box>
                </Box>
              </ContentBlock>
            </GDPRNoticeContainer>
          </ColorModeProvider>
        );
      }}
    />
  );
};

GDPRNotice.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default GDPRNotice;
