import styled from "styled-components";
import { Link } from "gatsby";
import css from "@styled-system/css";
import { Box } from "@dangerfarms/ui-system";
import {
  getHashFragment,
  isHash,
  linkResolver,
  normalizePrismicLink,
} from "@dangerfarms/prismic";
import { trackEvent } from "@dangerfarms/analytics";
import React from "react";

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  display: inline-block;
`;

const NavLink = styled(Link)`
  display: block;
  height: 100%;
  text-decoration: none;

  ${css({
    color: "fg",
  })}

  ${ListItem}:not(:first-of-type) > & {
    padding-left: 25px;
  }

  // prettier-ignore
  ${ListItem}:not(:last-of-type) > & {
    padding-right: 25px;
  }
`;

const BorderBox = styled.span`
  border-top: solid 5px
    ${({ isActive, theme }) =>
      isActive ? theme.colors.accent : theme.colors.cloud};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  ${({ isActive, theme }) =>
    css({
      borderColor: isActive ? "accent" : "bg",
    })({ theme })}

  ${NavLink}:hover &,
  ${NavLink}:active & {
    ${css({
      borderColor: "accent",
    })}
  }
`;

const NavText = styled.span`
  display: inline-block;
  font-size: 0.65rem;
  text-transform: uppercase;
  width: max-content;
  letter-spacing: 0.05em;

  ${css({
    color: "fg",
    fontFamily: "standard",
    fontWeight: "bold",
    paddingBottom: "2em",
    paddingTop: "2.5rem",
  })}

  ${NavLink}:hover &,
  ${NavLink}:active & {
    ${css({
      color: "accent",
    })}
  }
`;

const FragmentNavLink = styled(NavLink).attrs({ as: "a" })`
  cursor: pointer;
`;

// Not documenting prop types of internal component
/* eslint-disable react/prop-types */
const DesktopNav = ({ navLinks, location, scrollToHandlers }) => (
  <Box as="nav" boxDisplay={["none", "flex"]} flexDirection={["column", "row"]}>
    <List>
      {navLinks.map(rawLink => {
        if (
          rawLink.link._linkType !== "Link.document" &&
          rawLink.link._linkType !== "Link.web"
        ) {
          throw new Error(
            `Unsupported link type ${rawLink.link._linkType} encountered when rendering header nav.`,
          );
        }

        const link = normalizePrismicLink(rawLink.link);
        const isInternal = link.link_type === "Document";

        if (isInternal) {
          const to = linkResolver(link);
          const isActive = location.pathname.startsWith(to);
          return (
            <ListItem key={rawLink.label}>
              <NavLink to={to}>
                <BorderBox isActive={isActive}>
                  <NavText isActive={isActive}>{rawLink.label}</NavText>
                </BorderBox>
              </NavLink>
            </ListItem>
          );
        } else if (isHash(link)) {
          const hashFragment = getHashFragment(link);

          const scrollToHandler = event => {
            event.preventDefault();
            if (hashFragment === "#contact") {
              trackEvent("Contact Form", "View");
            }
            scrollToHandlers[hashFragment.slice(1)]();
          };

          return (
            <ListItem key={rawLink.label}>
              <FragmentNavLink href={hashFragment} onClick={scrollToHandler}>
                <BorderBox>
                  <NavText>{rawLink.label}</NavText>
                </BorderBox>
              </FragmentNavLink>
            </ListItem>
          );
        } else {
          return (
            <ListItem key={rawLink.label}>
              <NavLink as="a" href={link.url}>
                <BorderBox>
                  <NavText>{rawLink.label}</NavText>
                </BorderBox>
              </NavLink>
            </ListItem>
          );
        }
      })}
    </List>
  </Box>
);
/* eslint-enable */

export default DesktopNav;
