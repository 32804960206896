import {
  Box,
  ColorModeProvider,
  Highlight,
  HorizontalLine,
  Section,
  Stack,
  VerticalLine,
} from "@dangerfarms/ui-system";
import { PrismicText } from "@dangerfarms/prismic";
import React from "react";
import Img from "gatsby-image";
import css from "@styled-system/css";
import styled from "styled-components";

const formatAboveBreakpointStyles = (styles, theme) => `
  @media screen and (min-width: ${theme.contentWidth[0]}) {
    ${styles}
  }
`;

const formatBelowBreakpointStyles = (styles, theme) => `
  @media screen and (max-width: ${parseInt(theme.contentWidth[0], 10) - 1}px) {
    ${styles}
  }
`;

const ProcessLede = styled(PrismicText)`
  max-width: 700px;

  & ${Highlight} {
    color: ${({ theme }) => theme.colors.fg};
  }
`;

const ProcessStages = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  ${({ theme }) => formatAboveBreakpointStyles("flex-direction: row;", theme)}
`;

const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  order: ${({ index }) => index * 2};
  width: 100%;

  ${({ index, theme }) =>
    formatBelowBreakpointStyles(
      index === 0
        ? `
          ${css({
            height: ["380px", "320px"],
          })};

          & > ${VerticalLine}:first-child { display: none; }
        `
        : `${css({
            height: ["430px", "370px"],
          })};`,
      theme,
    )}

  & > ${VerticalLine} {
    height: 50px;
  }

  ${({ index, left, theme }) =>
    formatAboveBreakpointStyles(
      `justify-content: space-between;
      width: calc(50% + 100px);
      height: ${index === 0 ? "270px" : "370px"};
      order: ${left ? index * 2 : index * 2 + 1};

      & > ${VerticalLine}:first-child {
        align-self: flex-${left ? "start" : "end"};
        height: calc(100% - 270px);
        margin-${left ? "left" : "right"}: 70px;
      }

      & > ${VerticalLine}${index !== 0 && ":last-child"} {
        display: none;
      }
      `,
      theme,
    )}
`;

const CardAndHorizontalLine = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 700px;

  & ${HorizontalLine} {
    display: none;
  }

  ${({ theme }) =>
    formatAboveBreakpointStyles(
      `& ${HorizontalLine} { display: block; }`,
      theme,
    )}
`;

const Card = styled(Box)`
  position: relative;
  width: 100%;

  ${css({
    borderRadius: 1,
    boxShadow: "mega",
    height: ["330px", "270px"],
    paddingX: ["32px", "70px"],
  })};

  ${({ theme }) =>
    formatBelowBreakpointStyles(
      `& ${VerticalLine} { margin-right: auto; }`,
      theme,
    )}

  ${({ left, theme }) =>
    formatAboveBreakpointStyles(
      `& ${VerticalLine} { margin-${left ? "right" : "left"}: auto; }`,
      theme,
    )}
`;

const CardTitle = styled(PrismicText)`
  margin-top: 15px;
`;

const Semicircle = styled.div`
  position: absolute;
  width: 100px;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.fg};

  ${({ theme }) =>
    formatBelowBreakpointStyles(
      `
      bottom: 0;
      left: calc(50% - 50px);
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
    `,
      theme,
    )}

  ${({ left, theme }) =>
    formatAboveBreakpointStyles(
      `
        top: calc(50% - 50px);
        ${left ? "right" : "left"}: 0;
        bottom: initial;
        width: 50px;
        height: 100px;
        border-bottom-${left ? "left" : "right"}-radius: 100px;
        border-top-${left ? "left" : "right"}-radius: 100px;
  `,
      theme,
    )}

  @media screen and (max-width: 400px) {
    left: calc(50% - 35px);
    width: 70px;
    height: 35px;
  }
`;

const Graphic = styled(Box)`
  display: flex;
  order: ${({ index }) => index * 2 + 1};
  width: 100%;
  height: ${({ height, index }) =>
    index !== 0 ? height : `${parseInt(height, 10) - 75}px`};

  & > ${Box} {
    align-self: center;
    margin-right: auto;
  }

  ${({ finalCard, height, index, left, theme }) =>
    formatAboveBreakpointStyles(
      `height: ${height};

      & > ${Box} {${
        index !== 0 ? " align-self: flex-end; " : ""
      } margin-right: ${finalCard ? "20px" : "auto"}; } order: ${
        left ? index * 2 + 1 : index * 2
      }; width: calc(50% - 100px)`,
      theme,
    )}

  @media screen and (max-width: 400px) {
    height: ${({ height, index }) =>
      index !== 0
        ? `${parseInt(height, 10) - 50}px`
        : `${parseInt(height, 10) - 125}px`};

    & > ${Box} {
      display: flex;
      align-items: center;
    }
  }
`;

const CardImage = styled(Img)`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 400px) {
    width: 250px;
    height: auto;
    margin: 0 auto;

    & img {
      // Override Gatsby Image inline style
      object-fit: contain !important;
    }
  }
`;

const CurvedLine = styled.div`
  display: none;
  width: 70px;
  height: ${({ height }) => height};
  margin-top: auto;
  margin-${({ left }) => (left ? "right" : "left")}: 70px;
  border-top-${({ left }) => (left ? "right" : "left")}-radius: 70px;
  border-top: 1px solid;
  border-${({ left }) => (left ? "right" : "left")}: 1px solid;
  border-color: ${({ theme }) => theme.colors.accent};

  ${({ theme }) => formatAboveBreakpointStyles("display: block;", theme)}
`;

// There's not much gain to be had from maintaining prop type definitions for
// this component, because they're passed from Gatsby, it's not an interface
// we'll ever need to use.
//
/* eslint-disable react/prop-types */
const ProcessSection = ({ process }) => (
  <Section backgroundColor="barelyGrey">
    <Stack space={3}>
      <Stack>
        <PrismicText richText={process.primary.title} variant="heading" />
        <ProcessLede
          richText={process.primary.lede1}
          variant="lede"
          withHighlights
        />
      </Stack>
      <ProcessStages>
        {process.fields.map((card, index) => {
          const cardOnLeft = index % 2 === 0;
          const stageHeight = index !== 0 ? "370px" : "270px";
          const finalCardIndex = process.fields.length - 1;
          return (
            <React.Fragment key={card.card_title[0].text}>
              <CardContainer index={index} left={cardOnLeft}>
                <VerticalLine />
                <CardAndHorizontalLine>
                  {!cardOnLeft && <HorizontalLine size="120px" />}
                  <ColorModeProvider mode="accent">
                    <Card bg="bg" left={cardOnLeft}>
                      <Stack>
                        <Box
                          boxDisplay="flex"
                          boxWidth="100%"
                          flexDirection="column"
                        >
                          <VerticalLine />
                          <CardTitle
                            richText={card.card_title}
                            variant="subheading"
                          />
                        </Box>
                        <PrismicText
                          richText={card.card_text}
                          textSize="15px"
                        />
                      </Stack>
                      <Semicircle left={cardOnLeft} />
                    </Card>
                  </ColorModeProvider>
                  {cardOnLeft && <HorizontalLine size="120px" />}
                </CardAndHorizontalLine>
                <VerticalLine />
              </CardContainer>
              <Graphic
                finalCard={index === finalCardIndex}
                height={stageHeight}
                index={index}
                left={cardOnLeft}
              >
                {!cardOnLeft && index !== finalCardIndex && (
                  <CurvedLine
                    height={index === 0 ? "50%" : "150px"}
                    index={index}
                  />
                )}
                <Box
                  boxHeight={index === 0 ? "106px" : "300px"}
                  boxWidth="300px"
                  marginLeft="auto"
                >
                  <CardImage
                    alt={card.card_image.alt}
                    fluid={card.card_imageSharp.childImageSharp.fluid}
                    title={card.card_image.alt}
                  />
                </Box>
                {cardOnLeft && (
                  <CurvedLine height={index === 0 ? "50%" : "150px"} left />
                )}
              </Graphic>
            </React.Fragment>
          );
        })}
      </ProcessStages>
    </Stack>
  </Section>
);
/* eslint-enable */

export default ProcessSection;
