import { trackCustomEvent } from "gatsby-plugin-google-analytics";

/**
 * Currently, valid arguments are:
 * `"Contact Form", "View"`
 * `"Contact Form", "Submit"`
 * `"Newsletter Form", "Submit"`
 * Other category + action arguments will track a new custom Google Analytics
 * event, but will not be tracked via Facebook Pixel or LinkedIn Insight.
 */
const trackEvent = (category, action) => {
  trackCustomEvent({
    category,
    action,
  });

  /**
   * See https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
   * for valid `facebookStandardEvent` arguments
   */
  const trackViaFacebookPixel = facebookStandardEvent =>
    typeof fbq === "function"
      ? fbq("track", facebookStandardEvent, { content_name: category }) // eslint-disable-line no-undef
      : null;

  /*
   * Conversion IDs can only be created here https://www.linkedin.com/campaignmanager
   * A new ID must be generated for each custom event. They are not sensitive because
   * they are meaningless without the corresponding Partner ID.
   */
  const trackViaLinkedInInsight = conversionId => {
    if (
      typeof document !== "undefined" &&
      process.env.GATSBY_LINKEDIN_INSIGHT_PARTNER_ID
    ) {
      const pixel = document.createElement("img");
      pixel.style.height = 1;
      pixel.style.width = 1;
      pixel.style.display = "none";
      pixel.src = `https://px.ads.linkedin.com/collect/?pid=${process.env.GATSBY_LINKEDIN_INSIGHT_PARTNER_ID}&conversionId=${conversionId}&fmt=gif`;
      document.body.append(pixel);
    }
  };

  if (category === "Contact Form") {
    if (action === "View") {
      trackViaFacebookPixel("ViewContent");
      trackViaLinkedInInsight("2199628");
    } else if (action === "Submit") {
      trackViaFacebookPixel("Contact");
      trackViaLinkedInInsight("2199620");
    }
  } else if (category === "Newsletter Form" && action === "Submit") {
    trackViaFacebookPixel("CompleteRegistration");
    trackViaLinkedInInsight("2199636");
  }
};

export default trackEvent;
