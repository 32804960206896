// Reach Router is available via Gatsby: cheeky but safe import here.
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from "@reach/router";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";

const headerNavComponentQuery = graphql`
  query headerNavComponent {
    prismic {
      allNav_linkss {
        edges {
          node {
            body {
              ... on PRISMIC_Nav_linksBodyLink {
                primary {
                  label
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                      }
                    }
                  }
                  enabled
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Nav = ({ onClickContactNav }) => {
  const location = useLocation();

  const scrollToHandlers = {
    contact: onClickContactNav,
  };

  // Note that both mobile + desktop navs are rendered simultaneously:
  // CSS is used to conditionally hide one or the other. This way we
  // we don't depend on runtime JS for a responsive nav.
  return (
    <StaticQuery
      query={`${headerNavComponentQuery}`}
      render={data => {
        // Quite a tricky data structure returned by Prismic's GraphQL
        // API here. In the end, navLinks looks something like this...
        // [
        //   {
        //     enabled: true,
        //     label: "Approach",
        //     link: {
        //       _linkType: "Link.document",
        //       meta: {
        //         uid: "approach",
        //         type: "approach",
        //       }
        //     },
        //   },
        //   {
        //     enabled: true,
        //     label: "Blog",
        //     link: {
        //       _linkType: "Link.web",
        //       url: "https://blog.dangerfarms.com/"
        //     }
        //   }
        //   ...
        // ]
        const navLinks = data.prismic.allNav_linkss.edges[0].node.body
          .map(body => body.primary)
          .filter(link => link.enabled);

        return (
          <React.Fragment>
            <MobileNav
              location={location}
              navLinks={navLinks}
              scrollToHandlers={scrollToHandlers}
            />
            <DesktopNav
              location={location}
              navLinks={navLinks}
              scrollToHandlers={scrollToHandlers}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

Nav.propTypes = {
  onClickContactNav: PropTypes.func.isRequired,
};

export default Nav;
