import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

/**
 * Hides non-prod environments from Google's web crawler.
 */
const GoogleIndexingMetadata = ({ env, force }) => {
  // This means that the "noindex" meta tag is only *removed* when in prod.
  // ie. all other environments are hidden from Google
  if (env === "prod" && !force) {
    return null;
  }

  return (
    <Helmet>
      <meta content="noindex" name="robots" />
    </Helmet>
  );
};

GoogleIndexingMetadata.propTypes = {
  env: PropTypes.oneOf(["local", "next", "prod"]),
  force: PropTypes.bool,
};

GoogleIndexingMetadata.defaultProps = {
  env: "local",
  force: false,
};

export default GoogleIndexingMetadata;
