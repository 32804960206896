import {
  ColorModeProvider,
  Column,
  VerticalLine,
  Stack,
} from "@dangerfarms/ui-system";
import React from "react";
import PropTypes from "prop-types";
import { PrismicText } from "@dangerfarms/prismic";
import css from "@styled-system/css";
import styled from "styled-components";

const Card = styled(Column)`
  width: 100%;

  ${css({
    backgroundColor: "bg",
    borderRadius: 1,
    boxShadow: "mega",
    marginBottom: "30px",
    maxWidth: [null, "calc((100% - 30px) / 2)"],
    paddingBottom: 6,
    paddingX: 3,
  })};

  // Switch to 4 cards per row at a custom breakpoint: the "wide" one
  // is too wide for this
  @media screen and (min-width: 1080px) {
    max-width: calc((100% - 100px) / 4);
  }
`;

const ApproachCard = ({ text, title }) => (
  <ColorModeProvider mode="accent">
    <Card>
      <Stack>
        <VerticalLine />
        <PrismicText
          richText={title}
          textSize="1.2rem"
          variant="standard"
          weight="bold"
          withHighlights
        />
        <PrismicText lineHeight="1.6" richText={text} textSize={3} />
      </Stack>
    </Card>
  </ColorModeProvider>
);

ApproachCard.propTypes = {
  text: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ApproachCard;
