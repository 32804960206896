import { graphql, StaticQuery } from "gatsby";
import { RichText } from "prismic-reactjs";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { ClientInfographic } from "@dangerfarms/ui-system";
import { prismicPropTypes } from "@dangerfarms/prismic";

const offsetAmount = 100;

const GridItem = styled.li``;

const Grid = styled.ul.withConfig({
  shouldForwardProp: prop => !["offset"].includes(prop),
})`
  display: grid;
  column-gap: ${({ theme }) => theme.rhythm(2)};
  grid-template-columns: auto;
  row-gap: ${({ theme }) => theme.rhythm(2)};
  list-style: none;
  justify-content: space-evenly;
  padding-bottom: ${({ offset }) => (offset ? `${offsetAmount}px` : null)};

  ${({ theme }) => theme.mediaQueries.desktop} {
    grid-template-columns: auto auto;
    row-gap: ${({ theme }) => theme.rhythm(4)};

    ${GridItem} {
      &:nth-child(even) {
        transform: ${({ offset }) =>
          offset ? `translateY(${offsetAmount}px)` : null};
      }
    }
  }
`;

const infographicBackgroundsQuery = graphql`
  query InfographicBackgrounds {
    allFile(filter: { relativeDirectory: { eq: "infographics" } }) {
      nodes {
        publicURL
        name
      }
    }
  }
`;

const CaseStudyGrid = ({ caseStudies }) => (
  <StaticQuery
    query={`${infographicBackgroundsQuery}`}
    render={data => {
      const infographicBackgrounds = data.allFile.nodes;

      return (
        <Grid offset={caseStudies.length >= 4}>
          {caseStudies.map(caseStudy => {
            const companyName = caseStudy.company_name
              ? RichText.asText(caseStudy.company_name)
              : "";
            const projectName = RichText.asText(caseStudy.project_name);
            const bigStatNumber = caseStudy.big_stat_number;
            const bigStatCaption = caseStudy.big_stat_caption;
            const infographicType = caseStudy.infographic_type;
            const uid = caseStudy._meta.uid;
            const background = infographicBackgrounds.find(
              node => node.name === infographicType,
            ).publicURL;

            return (
              <GridItem key={companyName + projectName}>
                <ClientInfographic
                  background={background}
                  client={companyName}
                  linkAddress={`/case-studies/${uid}`}
                  number={bigStatNumber}
                  project={projectName}
                  stat={bigStatCaption}
                />
              </GridItem>
            );
          })}
        </Grid>
      );
    }}
  />
);

CaseStudyGrid.propTypes = {
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      _meta: PropTypes.shape({
        uid: PropTypes.string.isRequired,
      }).isRequired,
      big_stat_caption: PropTypes.string.isRequired,
      big_stat_number: PropTypes.string.isRequired,
      company_name: prismicPropTypes.richText.isRequired,
      infographic_type: PropTypes.string.isRequired,
      project_name: prismicPropTypes.richText.isRequired,
    }),
  ).isRequired,
};

export const query = graphql`
  fragment CaseStudy on PRISMIC_Case_study {
    _meta {
      uid
    }
    company_logo_image
    big_stat_caption
    company_name
    big_stat_number
    project_name
    infographic_type
  }
`;

export default CaseStudyGrid;
