import { DfThemeProvider } from "@dangerfarms/ui-system";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useSpring } from "react-spring";
import { Helmet } from "react-helmet";
import BuildMetadata from "./BuildMetadata";
import ContactSection from "./ContactSection";
import GoogleIndexingMetadata from "./GoogleIndexingMetadata";
import NewsletterForm from "./NewsletterForm";
import OrganizationStructuredData from "./OrganizationStructuredData";
import WebPageStructuredData from "./WebPageStructuredData";
import Header from "./Header";
import Footer from "./Footer";
import GDPRNotice from "./GDPRNotice";

const GDPR_NOTICE_LOCAL_STORAGE_KEY = "dangerfarms.com_GDPR_notice";

const pageComponentQuery = graphql`
  query pageComponent {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

/**
 * The root component of all pages in the site.
 *
 * - renders common header and footer
 * - configures the page title + SEO metadata
 * - provides the styled-components theme to all children
 */
const Page = ({ children, description, noindex, title }) => {
  const [, setY] = useSpring(() => ({ y: 0 }));

  const [showGDPRNotice, setShowGDPRNotice] = useState(
    typeof window === "undefined"
      ? false
      : localStorage.getItem(GDPR_NOTICE_LOCAL_STORAGE_KEY) !== "closed",
  );

  const closeGDPRNotice = () => {
    localStorage.setItem(GDPR_NOTICE_LOCAL_STORAGE_KEY, "closed");
    setShowGDPRNotice(false);
  };

  const contactSectionRef = useRef(null);

  const scrollToContactSection = () =>
    setY({
      y: contactSectionRef.current.getBoundingClientRect().top + window.scrollY,
      reset: true,
      from: { y: window.scrollY },
      onFrame: ({ y }) => window.scroll(0, y),
    });

  return (
    <StaticQuery
      query={`${pageComponentQuery}`}
      render={data => {
        const siteTitle = data.site.siteMetadata.title;

        return (
          <DfThemeProvider>
            {/* Basic metadata: page title etc */}
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              <html lang="en" />
              <title>{title}</title>

              {process.env.GATSBY_BUILD_ENVIRONMENT === "prod" &&
                process.env.GATSBY_GA_OPTIMIZE_ID && (
                  <>
                    <style>{`.async-hide { opacity: 0 !important}`}</style>
                    <script>
                      {`
                  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
                  })(window,document.documentElement,'async-hide','dataLayer',4000,
                  {${process.env.GATSBY_GA_OPTIMIZE_ID}:true});
                `}
                    </script>
                  </>
                )}

              {!noindex && <meta content={description} name="description" />}
            </Helmet>

            {/* Developer-facing metadata */}
            <BuildMetadata sha={process.env.GATSBY_GIT_SHA} />

            {/* SEO metadata */}
            <GoogleIndexingMetadata
              env={process.env.GATSBY_BUILD_ENVIRONMENT}
              force={noindex}
            />
            <OrganizationStructuredData />
            <WebPageStructuredData
              description={description}
              title={title || siteTitle}
            />

            <Header onClickContactNav={scrollToContactSection} />
            <main>
              {children}
              <NewsletterForm />
              <div ref={contactSectionRef}>
                <ContactSection />
              </div>
            </main>
            <Footer />
            {showGDPRNotice && <GDPRNotice onClose={closeGDPRNotice} />}
          </DfThemeProvider>
        );
      }}
    />
  );
};

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.description && !props.noindex) {
    return new Error(
      `One of 'description' or 'noindex' is required by '${componentName}' component.`,
    );
  }

  return null;
};

const descriptionPropCheck = (props, propName, componentName) => {
  requiredPropsCheck(props, propName, componentName);

  if (props.description) {
    PropTypes.checkPropTypes(
      { description: PropTypes.string },
      { description: props.description },
      "prop",
      componentName,
    );
  }
};

const noindexPropCheck = (props, propName, componentName) => {
  requiredPropsCheck(props, propName, componentName);

  if (props.noindex) {
    PropTypes.checkPropTypes(
      { noindex: PropTypes.bool },
      { noindex: props.noindex },
      "prop",
      componentName,
    );
  }
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  description: descriptionPropCheck,
  noindex: noindexPropCheck,
  title: PropTypes.string,
};

Page.defaultProps = {
  description: null,
  noindex: false,
  title: null,
};

export default Page;
