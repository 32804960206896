import { Box, useTheme } from "@dangerfarms/ui-system";
import css from "@styled-system/css";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const galleryConfig = {
  images: [
    { width: "80vw", height: "45vw", zIndex: 1 },
    {
      width: "50vw",
      height: "75vw",
      alignRight: true,
      yOffset: "-15vw",
      zIndex: 2,
    },
    { width: "60vw", height: "60vw", yOffset: "-15vw", zIndex: 3 },
  ],
};

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${props => css({ height: props.height })}
`;

const clampVw = ({ theme, size }) => {
  const vwPercent = parseInt(size, 10) / 100;
  const wideBreakpoint = parseInt(theme.breakpoints.wide, 10);
  return `${vwPercent * wideBreakpoint}px`;
};

const getYDistanceToImage = i =>
  galleryConfig.images.slice(0, i).reduce((total, image) => {
    const heightRaw = parseInt(image.height, 10);

    if (image.yOffset) {
      const offsetRaw = parseInt(image.yOffset, 10);
      return total + heightRaw + offsetRaw;
    } else {
      return total + heightRaw;
    }
  }, 0);

const getGalleryHeight = n => {
  return `${getYDistanceToImage(n)}vw`;
};

const getImageTop = i => {
  if (i === 0) {
    return 0;
  }

  // Add up the heights of the preceding images first
  const yDistanceToImage = getYDistanceToImage(i);

  // Then add the yOffset for image i, if there is one
  if (galleryConfig.images[i].yOffset) {
    const offsetRaw = parseInt(galleryConfig.images[i].yOffset, 10);
    return `${yDistanceToImage + offsetRaw}vw`;
  }

  return `${yDistanceToImage}vw`;
};

const Img = styled(GatsbyImage)`
  ${css({
    borderRadius: 1,
    boxShadow: "large",
  })}
`;

const OverlappingImage = styled.div`
  z-index: ${({ zIndex }) => zIndex};

  ${({ theme, alignRight, height, top, width }) =>
    css({
      height: [height, null, clampVw({ theme, size: height })],
      position: "absolute",
      width: [width, null, clampVw({ theme, size: width })],
      top: [top, null, clampVw({ theme, size: top })],
      right: alignRight ? 0 : null,
    })({ theme })}

  ${Img} {
    height: 100%;
  }
`;

const ImageGallery = ({ images }) => {
  const theme = useTheme();

  if (images.length === 0) {
    return null;
  }

  // Handle a single image as a special case. The usual layout algorithm (below)
  // looks a little off-balance when there's just a single image
  if (images.length === 1) {
    return (
      <Box width="100%">
        <Img fluid={images[0].imageSharp.childImageSharp.fluid} />
      </Box>
    );
  }

  // Set height of the gallery based on the number of images
  const imageGallery = images.slice(0, 3);
  const galleryHeight = [
    getGalleryHeight(imageGallery.length),
    null,
    clampVw({ theme, size: getGalleryHeight(imageGallery.length) }),
  ];

  return (
    <Gallery height={galleryHeight}>
      {imageGallery.map((image, i) => (
        <OverlappingImage
          key={image.imageSharp.id}
          {...galleryConfig.images[i]}
          top={getImageTop(i)}
        >
          <Img
            fluid={image.imageSharp.childImageSharp.fluid}
            objectFit="cover"
          />
        </OverlappingImage>
      ))}
    </Gallery>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        dimensions: PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number,
        }),
        url: PropTypes.string,
      }),
      imageSharp: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.shape({
            aspectRatio: PropTypes.number,
            base64: PropTypes.string,
            sizes: PropTypes.string,
            src: PropTypes.string,
            srcSet: PropTypes.string,
          }),
        }),
        id: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default ImageGallery;
