import { Box, ContentBlock, Logo } from "@dangerfarms/ui-system";
import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Nav from "./Nav";

const InlineLink = styled(Link)`
  display: inline-block;
`;

const Header = ({ onClickContactNav }) => {
  return (
    <Box as="header" boxHeight="headerHeight" paddingX="gutter">
      <ContentBlock>
        <Box
          alignItems={["flex-start", "stretch"]}
          boxDisplay="flex"
          flexDirection={["column", "row"]}
        >
          <Box paddingTop={3}>
            <InlineLink aria-labelledby="df-header-logo-title" to="/">
              <Logo alt="Danger Farms" id="df-header-logo-title" />
            </InlineLink>
          </Box>

          <Box flex={1} marginTop={["1rem", 0]} />
          <Nav onClickContactNav={onClickContactNav} />
        </Box>
      </ContentBlock>
    </Box>
  );
};

Header.propTypes = {
  onClickContactNav: PropTypes.func.isRequired,
};

export default Header;
