import {
  Box,
  Button,
  Column,
  Columns,
  EmailIcon,
  Inline,
  Link,
  Section,
  Stack,
  Text,
  TextField,
} from "@dangerfarms/ui-system";
import { trackEvent } from "@dangerfarms/analytics";
import { graphql, StaticQuery } from "gatsby";
import React from "react";

const contactSectionComponentQuery = graphql`
  query contactSectionComponent {
    prismic {
      allFooter_and_contacts {
        edges {
          node {
            address_1
            address_2
            city
            contact_heading
            email
            form_subHeading
            postcode
            submit_button_text
          }
        }
      }
    }
    site {
      siteMetadata {
        address {
          city
          line1
          line2
          postcode
        }
        baseUrl
        formspreeFormId
        email
      }
    }
  }
`;

const ContactSection = () => {
  return (
    <StaticQuery
      query={`${contactSectionComponentQuery}`}
      render={data => {
        const { formspreeFormId } = data.site.siteMetadata;

        const {
          address_1,
          address_2,
          city,
          contact_heading,
          email,
          form_subHeading,
          postcode,
          submit_button_text,
        } = data.prismic.allFooter_and_contacts.edges[0].node;

        return (
          <Section bg="barelyGrey" id="contact">
            <Stack space={[2, 3]}>
              <Text variant="heading">{contact_heading}</Text>

              <Columns space={3}>
                <Column width="33%">
                  <address>
                    <Stack space={[0.5, 2]}>
                      <Text variant="subheading">Danger Farms</Text>

                      <Stack space={0.5}>
                        <Text>
                          {address_1}
                          <br />
                          {address_2}
                          <br />
                          {postcode}
                          <br />
                          {city}
                        </Text>

                        <Inline space={0.5}>
                          <EmailIcon id="contact-section-email-icon" />
                          <Text>
                            <Link href={`mailto:${email}`}>{email}</Link>
                          </Text>
                        </Inline>
                      </Stack>
                    </Stack>
                  </address>
                </Column>
                <Column flex={1}>
                  <Stack align="stretch" space={[1, 2]}>
                    <Text variant="subheading">{form_subHeading}</Text>

                    <form
                      action={`https://formspree.io/${formspreeFormId}`}
                      method="POST"
                      onSubmit={() => trackEvent("Contact Form", "Submit")}
                    >
                      <Stack align="stretch">
                        <TextField
                          id="get-in-touch-name"
                          label="Name"
                          name="name"
                          required
                          type="text"
                        />

                        <TextField
                          autoCapitalize="false"
                          autoCorrect="false"
                          id="get-in-touch-email"
                          label="Email"
                          name="_replyto"
                          required
                          type="email"
                        />

                        <TextField
                          id="get-in-touch-company"
                          label="Company (optional)"
                          name="company"
                          type="text"
                        />

                        <TextField
                          id="get-in-touch-enquiry"
                          label="Enquiry"
                          multiline
                          name="enquiry"
                          required
                          rows={5}
                        />

                        <Box
                          alignItems="stretch"
                          alignSelf={["stretch", "flex-end"]}
                          boxDisplay="flex"
                          flexDirection="column"
                        >
                          <Button type="submit">{submit_button_text}</Button>
                        </Box>
                      </Stack>
                    </form>
                  </Stack>
                </Column>
              </Columns>
            </Stack>
          </Section>
        );
      }}
    />
  );
};

export default ContactSection;
