import { PrismicText } from "@dangerfarms/prismic";
import { Box } from "@dangerfarms/ui-system";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const PADDING_X_AROUND_GRID = "70px";
const MOBILE_LOGO_CELL_SIZE = "160px";

const LogoGridLayout = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;

  @media screen and (min-width: calc(${PADDING_X_AROUND_GRID} + ${MOBILE_LOGO_CELL_SIZE} * 2)) {
    justify-content: flex-start;
  }
`;

const LogoCellContainer = styled.li`
  display: inline-block;
  vertical-align: baseline;
`;

// This approach to the borders doesn't match the design, but that would require
// calculating the number of cells per row and other algorithmic shenanigans,
// which should probably be deferred at this stage
const LogoCell = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid rgba(250, 251, 251, 0.2);
  border-bottom: 1px solid rgba(250, 251, 251, 0.2);

  @media (max-width: 519px) {
    border-left: 1px solid rgba(250, 251, 251, 0.2);
  }
`;

const LogoImage = styled.img`
  width: 100%;
`;

const LogoGrid = ({ logos }) => (
  <LogoGridLayout>
    {logos.map(logo => (
      <LogoCellContainer key={logo.image_caption[0].text}>
        <LogoCell
          boxHeight={[MOBILE_LOGO_CELL_SIZE, "232px"]}
          boxWidth={[MOBILE_LOGO_CELL_SIZE, "232px"]}
          paddingBottom={["10%", "15%"]}
          paddingTop={["20%", "25%"]}
        >
          <Box
            boxDisplay="flex"
            boxHeight={["64px", "80px"]}
            boxWidth={["64px", "80px"]}
          >
            <LogoImage alt={logo.image.alt} src={logo.image.url} />
          </Box>
          <PrismicText richText={logo.image_caption} weight="news" />
        </LogoCell>
      </LogoCellContainer>
    ))}
  </LogoGridLayout>
);

LogoGrid.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      image_caption: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  ).isRequired,
};

export default LogoGrid;
