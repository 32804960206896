import { ExternalLink, Inline } from "@dangerfarms/ui-system";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import css from "@styled-system/css";
import { socialIcons } from "./socialIcons";

const SvgAnchor = styled(ExternalLink)`
  display: block;

  // Take advantage of the hoverable className that SVG icons use to expose
  // their hoverable elements. See Icon docs in Storybook.
  &:hover .hoverable {
    ${css({
      fill: "accent",
    })}
  }
`;

const socialMediaLinksComponentQuery = graphql`
  query socialMediaLinksComponent {
    site {
      siteMetadata {
        socialMediaLinks {
          name
          active
          url
        }
      }
    }
  }
`;

const SocialMediaLinks = () => (
  <StaticQuery
    query={`${socialMediaLinksComponentQuery}`}
    render={data => {
      const { socialMediaLinks } = data.site.siteMetadata;

      return (
        <Inline space={1} verticalAlign="middle">
          {socialMediaLinks
            .filter(link => link.active === true)
            .map(link => {
              const Icon = socialIcons[link.name.toLowerCase()];
              const alt = link.alt || link.name;
              const id = `${link.name.toLowerCase()}-footer-icon`;

              return (
                <SvgAnchor key={link.name} aria-label={alt} href={link.url}>
                  <Icon alt={alt} height="20px" id={id} width="20px" />
                </SvgAnchor>
              );
            })}
        </Inline>
      );
    }}
  />
);

export default SocialMediaLinks;
