import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const StructuredData = ({ data }) => (
  <Helmet>
    <script type="application/ld+json">{JSON.stringify(data)}</script>
  </Helmet>
);

StructuredData.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default StructuredData;
