import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

/**
 * Renders the git sha as a meta tag if provided. For example:
 * <meta name="build-sha" content="b2jf9dg3" />
 *
 * This helps debug deployments: devs can easily inspect the HTML
 * to verify that they're looking at the expected version of the code.
 */
const BuildMetadata = ({ sha }) => {
  if (!sha) {
    return null;
  }

  return (
    <Helmet>
      <meta content={sha} name="build-sha" />
    </Helmet>
  );
};

BuildMetadata.propTypes = {
  sha: PropTypes.string,
};

BuildMetadata.defaultProps = {
  sha: null,
};

export default BuildMetadata;
