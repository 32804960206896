import {
  FacebookIcon,
  GithubIcon,
  GitlabIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
} from "@dangerfarms/ui-system";

const socialIcons = {
  facebook: FacebookIcon,
  github: GithubIcon,
  gitlab: GitlabIcon,
  instagram: InstagramIcon,
  linkedin: LinkedInIcon,
  twitter: TwitterIcon,
};

export { socialIcons };
