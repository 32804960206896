import css from "@styled-system/css";
import {
  Box,
  Columns,
  Column,
  ColumnSpacer,
  ContentBlock,
  ColorModeProvider,
  Logo,
  Stack,
  Text,
} from "@dangerfarms/ui-system";
import { graphql, Link as GatsbyLink, StaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import SocialMediaLinks from "@dangerfarms/website-core/SocialMediaLinks";
import {
  getHashFragment,
  isHash,
  linkResolver,
  normalizePrismicLink,
} from "@dangerfarms/prismic";
import { trackEvent } from "@dangerfarms/analytics";

const Divider = styled.div`
  ${css(theme => ({
    borderLeft: [0, `solid 2px ${theme.colors.accent}`],
    borderTop: [`solid 2px ${theme.colors.accent}`, 0],
    height: [0, "100%"],
  }))}
`;

const Link = styled(GatsbyLink)`
  text-decoration: none;

  &:hover > ${Text} {
    ${css({
      color: "accent",
    })}
  }
`;

const ListItem = styled.li`
  list-style: none;
`;

const footerComponentQuery = graphql`
  query footerComponent {
    prismic {
      allNav_linkss {
        edges {
          node {
            body {
              ... on PRISMIC_Nav_linksBodyLink {
                primary {
                  label
                  link {
                    _linkType
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        uid
                      }
                    }
                  }
                  enabled
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        year
      }
    }
  }
`;

const Footer = () => {
  return (
    <StaticQuery
      query={`${footerComponentQuery}`}
      render={data => {
        const { title, year } = data.site.siteMetadata;

        const navLinks = data.prismic.allNav_linkss.edges[0].node.body
          .map(body => body.primary)
          .filter(link => link.enabled);

        return (
          <ColorModeProvider mode="dark">
            <Box as="footer" bg="bg" paddingX="gutter" paddingY={6}>
              <ContentBlock>
                <Columns align={["stretch", "center"]}>
                  <Column alignSelf="flex-start">
                    <Link aria-labelledby="df-footer-logo-title" to="/">
                      <Logo alt="Danger Farms" id="df-footer-logo-title" />
                    </Link>
                  </Column>
                  <Column alignSelf="stretch">
                    <Divider />
                  </Column>
                  <Column>
                    <Stack as="ul" space={0.5}>
                      {navLinks.map(rawLink => {
                        if (
                          rawLink.link._linkType !== "Link.document" &&
                          rawLink.link._linkType !== "Link.web"
                        ) {
                          throw new Error(
                            `Unsupported link type ${rawLink.link._linkType} encountered when rendering header nav.`,
                          );
                        }

                        const link = normalizePrismicLink(rawLink.link);
                        const isInternal = link.link_type === "Document";

                        if (isInternal) {
                          const to = linkResolver(link);
                          return (
                            <ListItem key={rawLink.label}>
                              <Link to={to}>
                                <Text variant="smallcaps" weight="news">
                                  {rawLink.label}
                                </Text>
                              </Link>
                            </ListItem>
                          );
                        } else if (isHash(link)) {
                          const hashFragment = getHashFragment(link);
                          return (
                            <ListItem key={rawLink.label}>
                              <Link
                                as="a"
                                href={hashFragment}
                                onClick={() =>
                                  hashFragment === "#contact"
                                    ? trackEvent("Contact Form", "View")
                                    : null
                                }
                              >
                                <Text variant="smallcaps" weight="news">
                                  {rawLink.label}
                                </Text>
                              </Link>
                            </ListItem>
                          );
                        } else {
                          return (
                            <ListItem key={rawLink.label}>
                              <Link as="a" href={link.url}>
                                <Text variant="smallcaps" weight="news">
                                  {rawLink.label}
                                </Text>
                              </Link>
                            </ListItem>
                          );
                        }
                      })}
                    </Stack>
                  </Column>

                  <ColumnSpacer />

                  <Column
                    alignItems={["flex-start", "flex-end"]}
                    alignSelf={["stretch", "flex-end"]}
                    boxDisplay="flex"
                    flexDirection="column"
                    marginTop={[2, 0]}
                  >
                    <Stack align={["flex-start", "flex-end"]} space={0.75}>
                      <SocialMediaLinks />
                      <Box paddingTop={[3, 1]}>
                        <Columns space={[0.5, 1]}>
                          <Column>
                            <Link aria-label="Cookie Policy" to="/cookies">
                              <Text as="span" variant="smallcaps" weight="news">
                                Cookies
                              </Text>
                            </Link>
                          </Column>
                          <Column>
                            <Link aria-label="Privacy Policy" to="/privacy">
                              <Text as="span" variant="smallcaps" weight="news">
                                Privacy
                              </Text>
                            </Link>
                          </Column>
                        </Columns>
                      </Box>
                      <Text textAlign={["left", "right"]} variant="smallprint">
                        ©{year} {title}. All rights reserved.
                      </Text>
                    </Stack>
                  </Column>
                </Columns>
              </ContentBlock>
            </Box>
          </ColorModeProvider>
        );
      }}
    />
  );
};

export default Footer;
